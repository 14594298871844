import React, { useState } from "react";
import Container from "./Components/loader"

import "./App.css";
// import Scene3d from "./features/Components/Scene";
// import Finger from "./features/Components/Finger";
import loadable from '@loadable/component'
const Scene3d = loadable(() => import("./Components/Scene"))
const Finger = loadable(() => import("./Components/Finger"))
const Panel = loadable(() => import("./Components/Panel"))

function App() {
  const [FingerDisp, setFingerDisp] = useState("none");
  const [SceneDIsp, setSceneDIsp] = useState(false);
  const [SceneDIsp2, setSceneDIsp2] = useState("");

  const [canvdisp, setcanvdisp] = useState("none");
  const [hand, handdisp] = useState("none");

  setTimeout(() => {
    setcanvdisp("block");
  }, 1000);

  const handleClick = () => {
    setFingerDisp("none");
  };
  const handleloaded = () => {
    setFingerDisp("");
  };

  const handleClickScene = () => {
    setSceneDIsp(true);
  };

  const getColorPressed = (value, index) => {
    SelectedMeshColor(value, index);
  };

  const SelectedMeshColor = (color, mesh) => {
    console.log(color, mesh);
    let event = new CustomEvent("selectedMesh-color", {
      detail: {
        color,
        mesh,
      },
    });
    window.dispatchEvent(event);
  };
  const timeout = () => {

    setTimeout(() => {
      setSceneDIsp2("none");
     
    }, 5000);
    
  };
  timeout()
  if (SceneDIsp) {
    return (
      <div className="App unselectable">
        <div style={{ display: canvdisp, height: "100%", width: "100%" }}>
        <div style={{display:SceneDIsp2}}>
         <Container  /></div>
          <Finger display={FingerDisp} style={{ display: FingerDisp }} />
          <Scene3d handleClick={handleClick} handleloaded={handleloaded}/>
          <Panel getColorPressed={getColorPressed} />
        </div>
      </div>
    );
  } else {
    //console.log(window.innerWidth)

    if(window.outerWidth>=470){
      return (
        <div >
          <button className="but" onClick={() => setSceneDIsp(true)}>                  
          <img  src="blurr-rolexjpg.jpg"></img>
  
            <img className="butimg" src="play-button.svg"></img>
          </button>
        </div>
      );
    }else{
      return (
        <div><img style={{    width: "100vw"}}  src="image (2).jpg"></img>
          <button className="but"  onClick={() => setSceneDIsp(true)}>                  
          
  
            <img className="butimg" src="play-button.svg"></img>
          </button>
        </div>
      );
   
    }
  }
}

export default App;
